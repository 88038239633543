<template>
  <div
    class="odds-wrap"
  >
    <template
      v-if="selectedGame"
    >
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        @scroll="handleOddsScroll"
      >
        <div
          class="scroll-area"
        >
          <div
            v-for="(odd, oIndex) in filteredItems.odds"
            :key="oIndex"
            class="odd"
          >
            <div
              class="odd-header"
              @click="handleCollapsedOdds(filteredItems.eventId , odd.id)"
            >
              <span> [{{ oIndex+1 }}] {{ odd.name }}</span>
              <span style="margin-left: auto; color: #00cfe8 !important;"> // {{ odd.id }} </span>
              <span
                class="arrow"
                :class="{ 'rotated': isCollapsedOdds(odd.id) }"
              >
                <feather-icon
                  icon="ChevronDownIcon"
                />
              </span>
            </div>
            <div
              class="odd-contents"
              :class="{ 'collapsed': isCollapsedOdds(odd.id) }"
            >
              <div class="contents-wrap">
                <!-- <div>{{ isTemplateType(odd) }} </div> -->
                <template v-if="isTemplateType(odd) === 'type-1'">
                  <div
                    v-if="false"
                  >
                    <!-- style="background-color: red;" -->
                    type-1
                  </div>
                  <template
                    v-for="(pivotedPart, gIndex) of pivotedParts1(odd)"
                  >
                    <!-- Header -->
                    <div
                      v-if="gIndex === 0"
                      :key="gIndex"
                      class="header w-100"
                    >
                      <div
                        class="btn-wrap"
                      >
                        <template
                          v-for="(header, hindex) in pivotedPart.header"
                        >
                          <div
                            :key="hindex"
                          >
                            <b-button
                              variant="outline-secondary"
                              disabled
                            >
                              <span> {{ header }}</span>
                            </b-button>
                          </div>
                        </template>
                      </div>
                    </div>
                    <!-- Contents -->
                    <div
                      v-else
                      :key="gIndex"
                      class="contents w-100"
                    >
                      <template
                        v-for="(content, cIndex) in pivotedPart.contents"
                      >
                        <div
                          :key="cIndex"
                          class="btn-wrap"
                        >
                          <div>
                            <b-button
                              variant="outline-secondary"
                              disabled
                            >
                              <span
                                class="none"
                              > {{ content.handicap }}</span>
                            </b-button>
                          </div>
                          <div
                            v-for="(part, psIndex) in content.participants"
                            :key="psIndex"
                          >
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              :class="{ 'cart-selected': filteredCartItems.includes(part.id) }"
                              variant="outline-secondary"
                              @click="$emit('addCart', filteredItems, odd, part)"
                            >
                              <span class="point">{{ part.value_eu }}</span>
                            </b-button>
                          </div>
                        </div>
                      </template>
                    </div>
                  </template>
                </template>

                <template v-if="isTemplateType(odd) === 'type-2'">
                  <div
                    v-if="false"
                  >
                    <!-- style="background-color: yellow;" -->
                    type-2
                  </div>
                  <template
                    v-for="(pivotedPart, gIndex) of pivotedParts2(odd)"
                  >
                    <!-- Header -->
                    <div
                      v-if="gIndex === 0"
                      :key="gIndex"
                      class="header"
                    >
                      <div
                        class="btn-wrap"
                      >
                        <template
                          v-for="(header, hindex) in pivotedPart.header"
                        >
                          <div
                            :key="hindex"
                          >
                            <b-button
                              variant="outline-secondary"
                              disabled
                            >
                              <span> {{ header }} </span>
                            </b-button>
                          </div>
                        </template>
                      </div>
                    </div>
                    <!-- Contents -->
                    <div
                      v-else
                      :key="gIndex"
                      class="contents"
                    >
                      <template
                        v-for="(content, cIndex) in pivotedPart.contents"
                      >
                        <div
                          :key="cIndex"
                          class="btn-wrap"
                        >
                          <div
                            v-for="(part, psIndex) in content.participants"
                            :key="psIndex"
                          >
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              :class="{ 'cart-selected': filteredCartItems.includes(part.id) }"
                              variant="outline-secondary"
                              @click="$emit('addCart', filteredItems, odd, part)"
                            >
                              <template v-if="part.handicap">
                                <span>{{ part.handicap }}</span>
                                <span class="bar">|</span>
                              </template>
                              <span class="point">{{ part.value_eu }}</span>
                            </b-button>
                          </div>
                        </div>
                      </template>
                    </div>
                  </template>
                </template>

                <template v-if="isTemplateType(odd) === 'type-3'">
                  <div
                    v-if="false"
                    style="background-color: green;"
                  >
                    type-3
                  </div>
                  <template
                    v-for="(groupedPart, gKey ,gIndex) in pivotedParts3(odd)"
                  >
                    <template v-if="gIndex === 0">
                      <div
                        v-if="groupedPart.length"
                        :key="gIndex"
                        class="header"
                      >
                        <div class="btn-wrap">
                          <template
                            v-for="(header, hIndex) in groupedPart"
                          >
                            <div
                              :key="hIndex"
                            >
                              <b-button
                                variant="outline-secondary"
                                disabled
                              >
                                <span> {{ header }} </span>
                              </b-button>
                            </div>
                          </template>
                        </div>
                      </div>
                    </template>
                    <template v-if="gIndex === 1">
                      <div
                        :key="gIndex"
                        class="contents"
                      >
                        <div class="btn-wrap">
                          <template
                            v-for="(part, pIndex) in groupedPart"
                          >
                            <div
                              :key="pIndex"
                            >
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                :class="{ 'cart-selected': filteredCartItems.includes(part.id) }"
                                variant="outline-secondary"
                                @click="$emit('addCart', filteredItems, odd, part)"
                              >
                                <span class="point">{{ part.value_eu }}</span>
                              </b-button>
                            </div>
                          </template>
                        </div>
                      </div>
                    </template>
                  </template>
                </template>

                <template v-if="isTemplateType(odd) === null">
                  <div
                    v-if="false"
                    style="background-color: blue;"
                  >
                    type-null
                  </div>
                  <template
                    v-for="(groupedPart, gKey, gIndex ) in groupedParts(odd)"
                  >
                    <!-- {{ odd.id }} ==> {{ groupedPart }}, {{ gKey }}, {{ gIndex }} -->
                    <div
                      :key="gIndex"
                      class="contents"
                    >
                      <div class="btn-wrap">
                        <template
                          v-for="(part, pIndex) in groupedPart"
                        >
                          <div
                            :key="pIndex"
                          >
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              :class="{ 'cart-selected': filteredCartItems.includes(part.id) }"
                              variant="outline-secondary"
                              @click="$emit('addCart', filteredItems, odd, part)"
                            >
                              <span>{{ part.name }}</span>
                              <span class="bar">|</span>
                              <span class="point">{{ part.value_eu }}</span>
                            </b-button>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </template>

              </div>
            </div>
          </div>
        </div>
      </vue-perfect-scrollbar>
    </template>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

// 쿠키에서 데이터를 가져오는 함수
function getCookie(name) {
  const cookies = document.cookie.split(';')
  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i].trim()
    if (cookie.startsWith(`${name}=`)) {
      const encryptedData = cookie.substring(name.length + 1)
      // 복호화하여 원래 데이터로 변환
      const decryptedData = decodeURIComponent(encryptedData)
      return decryptedData
    }
  }
  return null // 해당 이름의 쿠키가 없는 경우
}

// 쿠키에 데이터를 저장하는 함수
function setCookie(name, value, expires, path = '/', domain = document.domain, secure = false) {
  const encryptedData = encodeURIComponent(value)
  const expiresUTCString = new Date(Date.now() + (expires ? expires * 1000 : 30 * 60 * 1000)).toUTCString() // 기본 30분
  const cookieDomain = domain ? `; domain=${domain}` : ''
  const cookieSecure = secure ? '; secure' : ''

  document.cookie = `${name}=${encryptedData};${expiresUTCString}; path=${path}${cookieDomain}${cookieSecure}`
}

// 쿠키에 데이터를 삭제하는 함수
function deleteCookie(name) {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  // console.log('deleteCookie collapsedData')
}

export default {
  name: 'SportsInplayGamesOdds',
  components: {
    // BSV
    BButton,

    // 3rd party
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  filters: {
    formatHandicap: value => {
      if (value && typeof value === 'object' && Object.prototype.hasOwnProperty.call(value, 'handicap')) {
        return `${value.name} [${value.handicap}]`
      }
      return `${value.name}`
    },
  },
  props: {
    selectedGame: {
      type: Object,
      default: null,
    },
    cartItems: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      componentName: this.$options.name,

      divWidths: {
        oddsWrap: 0,
      },

      collapsedOdds: {},

      // vuePerfectScrollbar
      oddsScrollPosition: 0, // 초기 스크롤 위치
      perfectScrollbarSettings: {
        wheelSpeed: 0.5, // 마우스 휠로 스크롤할 때의 속도 조절 (0과 1 사이의 값)
        wheelPropagation: true, // 마우스 휠로 스크롤할 때 스크롤 이벤트가 부모 요소로 전파되는지 여부 결정 (true 또는 false)
        minScrollbarLength: 20, // 스크롤바의 최소 길이 설정 (픽셀 단위)
      },
    }
  },
  computed: {
    filteredItems() {
      let filteredItems = {}
      // if (!this.selectedGame || !this.selectedGame.game || !this.selectedGame.game.odds) {
      //   return filteredItems
      // }
      // filteredItems = this.selectedGame.game
      filteredItems = this.selectedGame
      return filteredItems
    },
    filteredCartItems() {
      return this.cartItems.map(item => {
        if (item.homeAway === 'Bonus') {
          return item.leagueName
        }
        return item.oddsId
      })
    },
    isTemplateType() {
      return value => {
        const oddId = value.id
        const selectedSport = this.selectedGame.sport.toLowerCase()
        const getType = {
          soccer: {
            'type-1': [1, 2, 16, 421, 520, 90006, 90008], // handicap O => header, index
            'type-2': [11, 12, 1777], // handicap O => header
            'type-3': [307, 318, 10562, 10563, 10565, 50246, 90308, 10115], // handicap X => header
          },
          basketball: {
            'type-1': [101, 103, 560, 564, 572, 584, 759, 760, 768, 1450, 2021, 2022, 180062, 180080, 180654, 5300563], // handicap O => header, index
            'type-2': [1446, 180061, 180079], // handicap O => header
            'type-3': [113, 449, 18059, 180032, 180060, 180077, 180190, 180647, 9200399], // handicap X => header
          },
          baseball: {
            'type-1': [900940, 922, 936, 160032, 160034, 160035, 160036, 900926, 900927, 900941, 900942, 900943, 11406301, 56112661, 561127113, 561127115, 561127116, 562127119, 562127120, 562127121], // handicap O => header, index
            'type-2': [937, 160031, 160037, 900948, 900949, 900953], // handicap O => header
            'type-3': [900945, 211, 924, 160030, 160038, 560006, 900924, 900928, 900929, 900930, 900931, 900946, 900947, 900958, 900959, 900968, 900969, 56112709, 561127082, 561127092], // handicap X => header
          },
          tennis: {
            'type-1': [66, 68, 10100, 10200, 25000, 80872, 80850, 80852, 80856, 80857, 80861, 800573, 80871, 800572, 130018, 130021, 130022, 130096, 800571, 7889152, 7889752, 7890352, 7892052], // handicap O => header, index
            'type-2': [1911, 130082, 130204, 130205], // handicap O => header
            'type-3': [12, 67, 536, 80872130015, 130020, 130215, 130238, 130015, 130016, 130239, 7888952, 7889052, 7897752, 7897852, 7897952, 7898052], // handicap X => header
          },
          volleyball: {
            'type-1': [116, 117, 146, 190, 191, 326, 327, 399, 54112719, 54112717, 54112659, 54112718], // handicap O => header, index
            'type-2': [121, 463], // handicap O => header
            'type-3': [114, 118, 119, 127, 160, 161, 462, 464, 112055, 112057, 112056], // handicap X => header
          },
          football: {
            'type-1': [], // handicap O => header, index
            'type-2': [], // handicap O => header
            'type-3': [], // handicap X => header
          },
          hockey: {
            'type-1': [83], // handicap O => header, index
            'type-2': [9312464], // handicap O => header
            'type-3': [54, 9632, 9200051, 9200452], // handicap X => header
          },
          esports: {
            'type-1': [444, 1667, 1668, 2523, 2524, 9510015, 9510016, 9510017, 9510018, 9510019, 9510020, 9510021, 9510022, 9510023, 9510024, 9510138, 9510139, 9591520, 1182795, 1182895, 9591540, 9591541, 9591545, 9591546, 9510158, 9510159], // handicap O => header, index
            'type-2': [443, 1659, 1660, 2518, 2519, 9510035, 9510036, 9510079], // handicap O => header
            'type-3': [410, 1179, 1180, 9291513, 9291514, 9510081, 9510082, 9209562, 9209563, 9290513, 9290514], // handicap X => header
          },
        }

        const typeValues = getType[selectedSport]
        if (!typeValues) {
          return null
        }
        const sportsType = Object.keys(typeValues).find(type => typeValues[type].includes(oddId)) || null

        return sportsType
      }
    },
    pivotedParts1() {
      return value => {
        const header = new Set([''])
        const contents = value.participants.reduce((acc, item) => {
          header.add(item.name) // 새로운 이름을 Set에 추가합니다.

          const handicapMap = acc[item.handicap] || (acc[item.handicap] = new Map())
          if (!handicapMap.has(item.name)) {
            handicapMap.set(item.name, item)
          }

          return acc
        }, {})

        const sortedContents = Object.entries(contents)
          // .sort(([handicapA], [handicapB]) => handicapB - handicapA) // handicap을 내림차순으로 정렬합니다.
          .sort(([handicapA], [handicapB]) => handicapA - handicapB) // handicap을 오름차순으로 정렬합니다.
          .map(([handicap, dataMap]) => ({
            handicap,
            participants: Array.from(dataMap.values()),
          }))

        const result = [
          { header: Array.from(header) },
          { contents: sortedContents },
        ]
        return result
      }
    },
    pivotedParts2() {
      return value => {
        // value 객체가 정의되어 있지 않은 경우 또는 participants 속성이 정의되어 있지 않은 경우에 대한 예외처리 추가
        if (!value || !value.participants) {
          console.error('Invalid value object or missing participants property')
          return [] // 또는 다른 기본값을 반환
        }

        const header = new Set()
        const contents = value.participants.reduce((acc, item) => {
          header.add(item.name) // 새로운 이름을 Set에 추가합니다.

          // 홈 팀인 경우 핸디캡 값을 그대로 사용하고, 아닌 경우 -1을 곱합니다.
          const handicapKey = item.name.toLowerCase() === 'home' ? parseFloat(item.handicap) : -parseFloat(item.handicap)

          const handicapMap = acc[handicapKey] || (acc[handicapKey] = new Map())
          if (!handicapMap.has(item.name)) {
            handicapMap.set(item.name, item)
          }

          return acc
        }, {})

        const sortedContents = Object.entries(contents)
          // .sort(([handicapA], [handicapB]) => parseFloat(handicapA) - parseFloat(handicapB)) // 오름차순 정렬
          .sort(([handicapA], [handicapB]) => parseFloat(handicapB) - parseFloat(handicapA)) // 내림차순 정렬
          .map(([handicap, dataMap]) => ({
            handicap,
            participants: Array.from(dataMap.values()),
          }))

        const result = [
          { header: Array.from(header) },
          { contents: sortedContents },
        ]

        return result
      }
    },
    pivotedParts3() {
      return value => {
        const parts = value.participants

        const header = new Set()
        // 객체 순서 배치 중요함(객체순으로 검사)
        const patterns = {
          odd_even: /odd|even/i,
          home_draw_away: /home|draw|away/i,
          yes_no: /yes|no/i,
        }

        parts.forEach(participant => {
          const name = participant.name.toLowerCase()
          Object.values(patterns).some(pattern => {
            const match = name.match(pattern)
            if (match && match[0]) {
              // header.add(match[0])
              header.add(participant.name)
              return true
            }
            return false
          })
        })

        return {
          header: [...header],
          contents: parts,
        }
      }
    },
    groupedParts() {
      return value => {
        const { participants } = value

        // nogoal 참가자와 일반 참가자 분리
        // const nogoalParticipants = participants.filter(part => /nogoal/i.test(part.name))
        // const regularParticipants = participants.filter(part => !/nogoal/i.test(part.name))
        const nogoalParticipants = participants.filter(part => /nogoal|X/i.test(part.name.replace(/ /g, '')))
        const regularParticipants = participants.filter(part => !(/nogoal|X/i.test(part.name.replace(/ /g, ''))))

        // nogoal 참가자를 우선으로 정렬하여 합치기
        const sortedParticipants = nogoalParticipants.concat(regularParticipants)

        // 중복값 제거
        const uniqueNames = [...new Set(participants.map(part => part.name))]

        // 그룹 사이즈 지정
        let groupSize
        if (participants.length >= 11) {
          groupSize = 3
        } else if (participants.length % 3 === 0) {
          groupSize = 3
        } else {
          groupSize = 2
        }

        // 그룹화
        // const groupedParts = Array.from({ length: Math.ceil(participants.length / groupSize) }, (_, i) => participants.slice(i * groupSize, (i + 1) * groupSize))
        const groupedParts = Array.from({ length: Math.ceil(sortedParticipants.length / groupSize) }, (_, i) => sortedParticipants.slice(i * groupSize, (i + 1) * groupSize))

        // return {
        //   header: uniqueNames,
        //   contents: groupedParts,
        // }

        return groupedParts
      }
    },
    groupedPartsMatrix() {
      return value => {
        const { participants } = value
        const xCategories = ['Home', 'Draw', 'Away']
        const yCategories = ['Yes', 'No']

        const result = participants.reduce((acc, { name }) => {
          const [xCategory, yCategory] = name.split('/')
          const newAcc = { ...acc } // 새로운 객체를 생성하여 재할당을 피합니다.
          newAcc[yCategory] = newAcc[yCategory] || {}
          newAcc[yCategory][xCategory] = newAcc[yCategory][xCategory] || null
          newAcc[yCategory][xCategory] = participants.find(p => p.name === `${xCategory}/${yCategory}`) || null
          return newAcc
        }, {})

        const ordered = {}
        yCategories.forEach(yCategory => {
          ordered[yCategory] = {}
          xCategories.forEach(xCategory => {
            ordered[yCategory][xCategory] = result[yCategory]?.[xCategory] || null
          })
        })

        return ordered
      }
    },

    isCollapsedOdds() {
      return index => {
        const { eventId } = this.selectedGame
        if (this.collapsedOdds[eventId]) {
          return this.collapsedOdds[eventId].indexOf(index) !== -1
        }
        return false
      }
    },
  },
  created() {
    // console.log('created()', this.componentName)
    // this.intervalId = setInterval(this.checkUserData, 1000)
    // window.addEventListener('storage', this.onLocalStorageChange)
    this.init()
  },
  beforeDestroy() {
    deleteCookie('collapsedData')
  },
  methods: {
    init() {
      this.collapsedOdds = JSON.parse(getCookie('collapsedData')) || {}
    },
    handleCollapsedOdds(eventId, itemId) {
      // 쿠키에서 collapsedData를 가져옵니다.
      let collapsedData = JSON.parse(getCookie('collapsedData'))

      if (!collapsedData) collapsedData = {}

      // eventId에 해당하는 항목을 찾아서 itemId를 추가하거나 제거합니다.
      if (!collapsedData[eventId]) collapsedData[eventId] = []

      const index = collapsedData[eventId].indexOf(itemId)
      if (index > -1) {
        collapsedData[eventId].splice(index, 1) // 제거
        if (collapsedData[eventId].length === 0) {
          delete collapsedData[eventId]
        }
      } else {
        collapsedData[eventId].push(itemId) // 추가
      }

      // 변경된 collapsedData를 쿠키에 저장합니다.
      setCookie('collapsedData', JSON.stringify(collapsedData), 30) // 쿠키는 30일 동안 유지됩니다.

      // 변경된 collapsedData를 상태에 반영하거나 필요한 동작을 수행합니다.
      this.collapsedOdds = JSON.parse(getCookie('collapsedData'))
    },
    handleOddsScroll(event) {
      this.oddsScrollPosition = event.target.scrollTop // 스크롤 위치 추적
    },
    onLocalStorageChange(event) {
      console.log('onLocalStorageChange111', event.key)
      if (event.key === 'userData') {
        const userData = JSON.parse(event.newValue)
        console.log('onLocalStorageChange22222', userData)
        if (!userData) {
          console.log('userData가 삭제되었습니다.')
          deleteCookie('userData')
        }
      }
    },
  },
}
</script>
<style scoped>
/* scoped 스타일을 사용하여 현재 컴포넌트에만 적용됩니다. */
.odds-wrap * {
  color: white;
  font-size: 0.9rem;
}

.odds-wrap .ps-container {
  padding-right: 15px;
  height: 1400px;
}

.odds-wrap .odd {
  padding-right: 3px;
  padding-left: 3px;
  margin-bottom: 0.5rem;
}

/* odd > header */
.odds-wrap .odd .odd-header {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  width: 95%;
  padding: 5px;
  margin: 0 auto;
  background-color: #161d31;
  border: 1px solid rgba(160, 160, 160, 0.2);
  border-radius: 5px;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
}

/* odd > contents */
.odds-wrap .odd .odd-contents {
  background-color: #283046;
  border-radius: 0.428rem;
  padding: calc(20px + 0.5rem) 0.3rem 0.3rem 0.3rem ; /* header-contents 겹치게 */
  margin-top: -20px; /* header-contents 겹치게 */

  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
  overflow: hidden; /* box-shadow를 border-radius에 맞춰 둥글게 처리 */
}
.odds-wrap .odd .odd-contents .contents-wrap {
  background-color: #283046;
  border-radius: 0.428rem;
  padding: 0.3rem 0.3rem 0.3rem 0.3rem ;
  overflow: hidden; /* box-shadow를 border-radius에 맞춰 둥글게 처리 */
}

/* odd > contents > header */
.odds-wrap .odd .odd-contents .header > div.btn-wrap {
  display: flex;
}
.odds-wrap .odd .odd-contents .header > div.btn-wrap > div {
  flex: 1;
}
.odds-wrap .odd .odd-contents .header.w-100 > div.btn-wrap > div:first-child {
  flex: 0 0 100px;
}

/* odd > contents > contents */
.odds-wrap .odd .odd-contents .contents > div.btn-wrap {
  display: flex;
}
.odds-wrap .odd .odd-contents .contents > div.btn-wrap div {
  flex: 1;
  position: relative;
  z-index: 1;
}
.odds-wrap .odd .odd-contents .contents.w-100 > div.btn-wrap div:first-child {
  flex: 0 0 100px;
}
.contents-wrap:not(:has(> .header)) .contents:first-of-type {
  border-top: 0.5px solid #5a5a5a;
}
.odds-wrap .odd .odd-contents .header > div.btn-wrap button,
.odds-wrap .odd .odd-contents .contents > div.btn-wrap button {
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  border-radius: 0.2rem;
  border: none !important;
  position: relative;
  z-index: 1;
}
.odds-wrap .odd .odd-contents .header > div.btn-wrap button {
  background-color: #262b37;
}
.odds-wrap .odd .odd-contents .contents > div.btn-wrap button {
  background-color: #283046;
  display: flex;
  justify-content: space-between;
}
.odds-wrap .odd .odd-contents .header > div.btn-wrap button::after,
.odds-wrap .odd .odd-contents .contents> div.btn-wrap button::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-bottom: 0.5px solid #5a5a5a;
  top: 0;
  right: 0;
  height: 100%;
  border-right: 0.5px solid #5a5a5a;
  z-index: -1;
}

.odds-wrap .odd .odd-contents .header > div.btn-wrap div:last-child button::after,
.odds-wrap .odd .odd-contents .contents> div.btn-wrap div:last-child button::after{
  border-right: none;
}

.odds-wrap .odd .odd-contents .btn-wrap button.btn.disabled,
.odds-wrap .odd .odd-contents .btn-wrap button.btn:disabled {
  opacity: 1 !important;
}

.odds-wrap .odd .odd-contents .contents > div.btn-wrap button span {
  flex: 1;
  text-align: center;
}

.odds-wrap .odd .odd-contents .contents > div.btn-wrap button span.bar {
  flex: 0.1;
  color: #5a5a5a;
}

.odds-wrap .odd .odd-contents .contents > div.btn-wrap button .point {
  color: rgb(255, 198, 139);
}
.odds-wrap .odd .odd-contents .contents > div.btn-wrap button span.none {
  text-align: center;
  padding-right: 0;
  border-right: none;
}

/* 버튼 선택 애니메이션 효과 정의 */
.btn-wrap button.cart-selected {
  color: white !important;
  background: linear-gradient(to right, #bf53b1, #d369a4, #ff7c6e, #bf53b1);
  background-size: 400% 100% !important; /* 배경 크기를 세 배로 설정 */
  animation: gradient-slide 5s linear 1 forwards !important; /* 애니메이션 속성 적용 */
}
@keyframes gradient-slide {
  0% { background-position: 0 50%; }
  100% { background-position: 100% 50%; }
}

/* point up&down */
.btn-wrap div:has(.point){
  position: relative;
}

.btn-wrap div:has(.point) button .point::after {
  content: "";
  position: absolute;
  right: 3px;  /* 버튼과 화살표 간의 간격 조정 */
  width: 0;
  height: 0;
  border-style: solid;
  transition: opacity 0.5s; /* 0.5초 동안 서서히 사라지도록 */
}

.btn-wrap div:has(.point) button.point-up .point::after {
  top: 3px;  /* 버튼과 화살표 간의 간격 조정 */
  border-width: 0px 7px 7px 0px; /* 상, 우, 하, 좌 */
  transform: rotate(180deg); /* 위쪽 화살표를 나타내기 위해 45도 회전 */
  border-color: transparent transparent #ff0000 transparent; /* 화살표 색상 */
  animation: disappear 2.5s forwards; /* 1초 동안 애니메이션이 실행되고 끝난 후 사라지도록 */
}

.btn-wrap div:has(.point) button.point-down .point::after {
  bottom: 3px;
  border-width: 0px 7px 7px 0px; /* 상, 우, 하, 좌 */
  transform: rotate(270deg); /* 아래쪽 화살표를 나타내기 위해 -45도 회전 */
  border-color: transparent transparent #0000ff transparent; /* 화살표 색상 */
  animation: disappear 2.5s forwards; /* 1초 동안 애니메이션이 실행되고 끝난 후 사라지도록 */
}

.btn-wrap div:has(.point) button:not(.point-up, .point-down) .point::after {
  content: none;
}

@keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* odd > contents > contents:collapse */
.odds-wrap .odd .odd-header .arrow {
  transition: transform 0.05s ease; /* transform 속성에 대한 애니메이션 효과 설정 */
}

.odds-wrap .odd .odd-header .arrow.rotated {
  transform: rotate(90deg); /* 회전된 상태에 대한 스타일 설정 */
}

.odds-wrap .odd .odd-contents:not(.collapsed) {
  max-height: 1000px;
  opacity: 1;
  overflow: hidden;
  transition: all 0.05s ease;
}

.odds-wrap .odd .odd-contents.collapsed {
  max-height: 0px !important;
  padding-bottom: 0rem !important;
  overflow: hidden;
  transition: all 0.05s cubic-bezier(0.5, 0.1, 0.3, 1)
}

.odds-wrap .odd .odd-contents.collapsed .btn-wrap {
  opacity: 0 !important;
  transition: all 0.05s cubic-bezier(0.5, 0.1, 0.3, 1)
}

</style>
